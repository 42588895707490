export const backendUrl = () => {
    if (window.location.href === "http://localhost:3000") {
        return "http://localhost:3001"
    }    
    return "https://capstone-backend-young-pond-3810.fly.dev"
}

export const createPost = async (data) => {
    let url = backendUrl();	
    return await fetch(url + "/posts", {
        method: "POST",
        body: data,
    });
}

export default backendUrl;